import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/button"
import Newsletter from "../components/newsletter"

const ArticlePage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="max-w-screen-md mx-auto px-4 mt-20">
      <p className="uppercase tracking-widest">20. May 2021</p>
      <h1 className="font-display font-bold text-4xl lg:text-5xl mt-4 leading-tight">Data centres will need about 4 to 6% of the world's energy in 2022.</h1>
      <div className="prose-xl prose-white mt-8">
        <p>ECO-Qube will also take smart workload orchestration to another step with the successful integration of the zonal heat management system. Before ECO-Qube, smart workload orchestration systems were utilizing servers randomly, which might be unbalancing the thermal equilibrium in data centres. With the help of zonal heat management, smart workload orchestration will be able to transfer the workload from hot areas to colder areas to keep the system thermally balanced for even better energy efficiency. Furthermore, to benefit from different outside variables (like seasonal characteristics, outside temperature and time differences), smart workload orchestration of ECO-Qube will be able to transfer the workload between different data centres from different buildings, cities, countries and continents to benefit from the best conditions for the minimum energy consumption. For example, the workload can be transferred from a cloudy French area to sunny Spanish area to benefit from higher energy generation of PV panels.</p>
        <p>Minimizing the energy consumption of the servers with smarts scheduling will also consequently reduce the power consumption of cooling systems. On the other hand, ECO-Qube aims at transforming static cooling systems in dynamic cooling systems. Thermal equilibrium is important for data centres because, on the basis of the second law of thermodynamics, energy from the warmer zone will flow to the colder zones increasing the overall entropy of the system. To bring the system to thermal equilibrium will take a significant amount of energy which should not be spent. ECO-Qube’s zonal heat management will use the instant data from active components to track the temperature changes in advance. This will provide ECO-Qube the flexibility to provide the adequate amount of inlet air flow between desired temperatures to maintain the thermal equilibrium.</p>
      </div>
      <div className="mt-8">
        <Button>Back to News</Button>
      </div>
    </div>
    <div className="max-w-screen-sm mx-auto px-4 mt-20 lg:mt-40 relative">
      <Newsletter />
    </div>
    
  </Layout>
)

export default ArticlePage
